<template>
  <div class="page page-login">
    <template v-if="!registed">
      <div class="register-box">
        <h2 class="text-center">千禾会员注册</h2>
        <!-- <a-divider /> -->
        <div class="v-pa-lg v-mt-lg">
          <a-steps :current="currentStep" size="small">
            <a-step title="设置登录账号" />
            <a-step title="填报资料" />
            <a-step title="申请成功" />
          </a-steps>
        </div>
        <div class="step-content" style="padding-top: 20px">
          <transition name="slide-fade" mode="out-in">
            <div class="step-content-1" v-if="currentStep === 0" style="margin: 0 auto; width: 320px">
              <a-form-model ref="registForm" :model="registForm">
                <a-form-model-item prop="userName">
                  <a-input size="large" v-model="registForm.userName" placeholder="用户名" ref="userName" autocomplete="off">
                    <a-icon slot="prefix" type="user" />
                  </a-input>
                  <!-- <div class="ant-form-explain text-justify">不少于4位，只能由数字、英文或下划线组成</div> -->
                </a-form-model-item>
                <a-form-model-item prop="password">
                  <a-input-password size="large" v-model="registForm.password" placeholder="密码：至少6位，包含数字和字母" ref="password" autocomplete="off">
                    <a-icon slot="prefix" type="lock" />
                  </a-input-password>
                </a-form-model-item>
                <a-form-model-item prop="rePassword">
                  <a-input-password size="large" v-model="registForm.rePassword" placeholder="确认密码" ref="rePassword" autocomplete="off">
                    <a-icon slot="prefix" type="lock" />
                  </a-input-password>
                </a-form-model-item>
                <a-form-model-item prop="phone">
                  <a-input type="number" size="large" v-model="registForm.phone" placeholder="手机号码" ref="phone">
                    <a-icon slot="prefix" type="mobile" />
                  </a-input>
                </a-form-model-item>
                <a-form-model-item prop="smscode">
                  <a-input size="large" v-model="registForm.smscode" placeholder="短信验证码" ref="smscode" :max-length="6">
                    <a-icon slot="prefix" type="mail" />
                    <span slot="suffix" class="sms-send text-primary cursor-pointer" @click="showBox" v-if="!verified">发送验证码</span>
                    <span slot="suffix" class="sms-send text-grey" v-else>
                      重新发送（
                      <i>{{ second }}</i>
                      s）
                    </span>
                  </a-input>
                </a-form-model-item>
                <Verify @success="verifySuccess" :mode="'pop'" :captchaType="'blockPuzzle'" :imgSize="{ width: '330px', height: '155px' }" ref="verify"></Verify>
              </a-form-model>
              <p :style="{ marginBottom: '12px' }">
                <a-checkbox :checked="checked" @change="onChange">
                  勾选即同意
                  <span class="hover-line text-indigo">隐私保护</span>
                  及
                  <span class="hover-line text-indigo">用户服务协议</span>
                </a-checkbox>
              </p>
              <div class="text-center"><a-button class="full-width" type="primary" size="large" @click="handleRegist" :loading="registing">完成注册， 下一步完善申请人材料</a-button></div>
              <div class="text-center v-my-md">
                <router-link class="hover-line" :to="{ path: 'login' }">已有账号，立即登录</router-link>
              </div>
            </div>
            <div class="step-content-1" v-else-if="currentStep === 1" style="margin: 0 auto; width: 450px">
              <a-alert class="v-mb-sm" type="error" v-if="user.state === -1">
                <div slot="message" class="text-red">上次提交资料审核未通过，审核意见：{{ user.checkOpinion }}</div>
              </a-alert>
              <a-form-model ref="applyForm" :model="applyForm" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
                <a-form-model-item prop="userType">
                  <span slot="label">会员类别</span>
                  <a-radio-group v-model="applyForm.userType">
                    <a-radio :value="1">机构</a-radio>
                    <a-radio :value="2">个人</a-radio>
                  </a-radio-group>
                </a-form-model-item>
                <template v-if="applyForm.userType === 1">
                  <a-form-model-item prop="orgName">
                    <span slot="label">机构名称</span>
                    <a-input v-model="applyForm.orgName" placeholder="名称" ref="orgName" />
                  </a-form-model-item>
                  <a-form-model-item prop="orgType">
                    <span slot="label">机构性质</span>
                    <a-select placeholder="请选择" v-model="applyForm.orgType" ref="orgType">
                      <a-select-option v-for="item in orgTypes" :key="item.label">{{ item.label }}</a-select-option>
                    </a-select>
                    <div class="ant-form-explain text-red text-justify">此处机构只指NGO，事业单位认证请先完成个人实名认证，再进入个人中心申请</div>
                  </a-form-model-item>
                  <a-form-model-item prop="orgCode">
                    <span slot="label">机构代码</span>
                    <a-input v-model="applyForm.orgCode" placeholder="统一信用代码18位编号" ref="orgCode" />
                  </a-form-model-item>
                  <a-form-model-item prop="orgRegDate">
                    <span slot="label">注册日期</span>
                    <a-date-picker v-model="applyForm.orgRegDate" value-format="YYYY-MM-DD" placeholder="选择日期" style="width: 140px" ref="orgRegDate" />
                    <div class="ant-form-explain text-red text-justify">如果机构注册时间不满2年，不能发起项目申请</div>
                  </a-form-model-item>
                  <a-form-model-item prop="smscode">
                    <span slot="label">上传证件</span>
                    <div class="font-xs v-my-xs lineh-14 text-dark text-justify">为避免他人恶意注册，请上传机构登记证书的复印件，加盖公章并写明“用于千禾公益基金会网站注册申请”的字样。（不接受PS图片且必须保证证件信息清晰可见）：</div>
                    <!-- <a-upload-dragger
                      :action="`${baseURL}/file/upload`"
                      :data="{
                        params: JSON.stringify({ fileBelongId: userId, fileType: '101', fileSign: 'U01' }),
                      }"
                      :show-upload-list="{
                        showPreviewIcon: true,
                        showRemoveIcon: true,
                        showDownloadIcon: true,
                      }"
                      :file-list="orgFileList1"
                      :before-upload="beforeUploadFile"
                      @change="handleUploadOrgFileChange1"
                      @download="({ fileId, name }) => downloadFile({ fileId, belongId: userId, fileSign: 'U01', fileName: name })"
                      accept=".png, .jpg, .jpeg, .pdf, .doc, .docx"
                    >
                      <a-icon type="plus" />
                      <p class="text-faded">机构登记证书</p>
                    </a-upload-dragger> -->
                    <a-upload
                      :action="`${baseURL}/file/upload`"
                      :data="{
                        params: JSON.stringify({ fileBelongId: userId, fileType: '101', fileSign: 'U01' }),
                      }"
                      :show-upload-list="{
                        showPreviewIcon: true,
                        showRemoveIcon: true,
                        showDownloadIcon: true,
                      }"
                      :file-list="orgFileList1"
                      :before-upload="beforeUploadFile"
                      @preview="handlePreviewFile"
                      @change="handleUploadOrgFileChange1"
                      @download="({ fileId, name }) => downloadFile({ fileId, belongId: userId, fileSign: 'U01', fileName: name })"
                      list-type="picture-card"
                      class="avatar-uploader"
                      accept=".png, .jpg, .jpeg"
                    >
                      <div v-if="orgFileList1.length === 0">
                        <a-icon type="plus" />
                        <div class="ant-upload-text">机构登记证书</div>
                      </div>
                    </a-upload>
                    <div class="font-xs v-mb-xs lineh-14 text-dark text-justify" style="margin-top: -16px">如因换证等原因导致证件上没有法定注册时间，请上传可证明此的其他证据（选填）：</div>
                    <!-- <a-upload-dragger
                      :action="`${baseURL}/file/upload`"
                      :data="{
                        params: JSON.stringify({ fileBelongId: userId, fileType: '102', fileSign: 'U01' }),
                      }"
                      :show-upload-list="{
                        showPreviewIcon: true,
                        showRemoveIcon: true,
                        showDownloadIcon: true,
                      }"
                      :file-list="orgFileList2"
                      :before-upload="beforeUploadFile"
                      @change="handleUploadOrgFileChange2"
                      @download="({ fileId, name }) => downloadFile({ fileId, belongId: userId, fileSign: 'U01', fileName: name })"
                      accept=".png, .jpg, .jpeg, .pdf, .doc, .docx"
                    >
                      <a-icon type="plus" />
                      <p class="text-faded">其他证明</p>
                    </a-upload-dragger> -->
                    <a-upload
                      :action="`${baseURL}/file/upload`"
                      :data="{
                        params: JSON.stringify({ fileBelongId: userId, fileType: '102', fileSign: 'U01' }),
                      }"
                      :show-upload-list="{
                        showPreviewIcon: true,
                        showRemoveIcon: true,
                        showDownloadIcon: true,
                      }"
                      :file-list="orgFileList2"
                      :before-upload="beforeUploadFile"
                      @preview="handlePreviewFile"
                      @change="handleUploadOrgFileChange2"
                      @download="({ fileId, name }) => downloadFile({ fileId, belongId: userId, fileSign: 'U01', fileName: name })"
                      list-type="picture-card"
                      class="avatar-uploader"
                      accept=".png, .jpg, .jpeg"
                    >
                      <div v-if="orgFileList2.length === 0">
                        <a-icon type="plus" />
                        <div class="ant-upload-text">其他证明</div>
                      </div>
                    </a-upload>
                    <div style="margin-top: -16px"></div>
                  </a-form-model-item>
                </template>
                <template v-else>
                  <a-form-model-item prop="realName">
                    <span slot="label">真实姓名</span>
                    <a-input v-model="applyForm.realName" placeholder="姓名" ref="realName" />
                  </a-form-model-item>
                  <a-form-model-item prop="nationality">
                    <span slot="label">国籍</span>
                    <a-select placeholder="请选择" v-model="applyForm.nationality" ref="nationality">
                      <a-select-option v-for="item in nationalities" :key="item.label">{{ item.label }}</a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <a-form-model-item prop="idCardNo">
                    <span slot="label">身份证号</span>
                    <a-input v-model="applyForm.idCardNo" placeholder="证件号码" ref="idCardNo" />
                    <div class="ant-form-explain text-red">个人类项目申请者必须是年满18周岁的中国公民</div>
                  </a-form-model-item>
                  <a-form-model-item prop="smscode">
                    <span slot="label">上传身份证</span>
                    <div class="flex" style="height: 104px; overflow: hidden">
                      <div class="flex-item">
                        <a-upload
                          :action="`${baseURL}/file/upload`"
                          :data="{
                            params: JSON.stringify({ fileBelongId: userId, fileType: '201', fileSign: 'U01' }),
                          }"
                          :file-list="idFileList1"
                          :before-upload="beforeUploadFile"
                          @preview="handlePreview"
                          @change="handleIdFileChange1"
                          @download="({ fileId, name }) => downloadFile({ fileId, belongId: userId, fileSign: 'U01', fileName: name })"
                          list-type="picture-card"
                          class="avatar-uploader"
                          accept=".png, .jpg, .jpeg"
                        >
                          <!-- <img v-if="idFile1" :src="idFile1" alt="avatar" /> -->
                          <div v-if="idFileList1.length === 0">
                            <a-icon type="plus" />
                            <div class="ant-upload-text">身份证正面</div>
                          </div>
                        </a-upload>
                      </div>
                      <div class="flex-item">
                        <a-upload
                          :action="`${baseURL}/file/upload`"
                          :data="{
                            params: JSON.stringify({ fileBelongId: userId, fileType: '202', fileSign: 'U01' }),
                          }"
                          :file-list="idFileList2"
                          :before-upload="beforeUploadFile"
                          @preview="handlePreview"
                          @change="handleIdFileChange2"
                          list-type="picture-card"
                          class="avatar-uploader"
                          accept=".png, .jpg, .jpeg"
                        >
                          <div v-if="idFileList2.length === 0">
                            <a-icon type="plus" />
                            <div class="ant-upload-text">身份证反面</div>
                          </div>
                        </a-upload>
                      </div>
                    </div>
                    <div class="font-xs v-my-xs lineh-14 text-dark text-justify">为避免他人恶意注册，请上传手持证件照（请您手持证件举在胸前,勿遮挡面部和证件有效信息，确保包括证件号在内的图片信息清晰可见）：</div>
                    <div class="flex" style="height: 104px; overflow: hidden">
                      <div class="flex-item">
                        <a-upload
                          :action="`${baseURL}/file/upload`"
                          :data="{
                            params: JSON.stringify({ fileBelongId: userId, fileType: '203', fileSign: 'U01' }),
                          }"
                          :file-list="idFileList3"
                          :before-upload="beforeUploadFile"
                          @preview="handlePreview"
                          @change="handleIdFileChange3"
                          list-type="picture-card"
                          class="avatar-uploader"
                          accept=".png, .jpg, .jpeg"
                        >
                          <div v-if="idFileList3.length === 0">
                            <a-icon type="plus" />
                            <div class="ant-upload-text">手持证件照</div>
                          </div>
                        </a-upload>
                      </div>
                    </div>
                  </a-form-model-item>
                </template>
              </a-form-model>
              <div class="text-center"><a-button style="width: 414px" type="primary" size="large" @click="handleApply" :loading="applying">提交千禾审核</a-button></div>
              <div class="text-center v-my-md">
                <router-link class="hover-line" :to="{ path: 'login' }">已有账号，立即登录</router-link>
              </div>
            </div>
          </transition>
        </div>
        <!-- <a-divider><span class="text-grey" style="font-size: 14px">使用第三方账号注册</span></a-divider>
        <div class="text-center login-3rd">
          <a-space>
            <span title="微信"><a-icon type="wechat" /></span>
            <span title="QQ"><a-icon type="qq" /></span>
            <span title="微博"><a-icon type="weibo" /></span>
          </a-space>
        </div> -->
      </div>
    </template>
    <template v-else>
      <div class="bg-white" style="width: 600px; margin: 0 auto">
        <a-result status="success" title="注册资料提交成功" :sub-title="`千禾审核需1-3个工作日，审核结果将通过短信发至您的手机${registForm.phone}`">
          <template #extra>
            <a-button key="login" type="primary" @click="$router.push('login')">去登录</a-button>
            <a-button key="index" @click="goHome">返回首页</a-button>
          </template>
        </a-result>
      </div>
    </template>
    <a-modal :width="600" :body-style="{ padding: '5px', backgroundColor: 'rgba(255,255,255, 0.4)' }" :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { userRegist, userApply, fetchById, registSms } from '@/api/user'
import Verify from './common/captcha/Verify'

export default {
  components: {
    Verify,
  },
  data() {
    return {
      checked: true,
      currentStep: 0,
      userId: null,
      registForm: {
        phone: '',
        smscode: '',
        userName: '',
        password: '',
        rePassword: '',
      },
      applyForm: {
        userId: null,
        userType: 1,
        orgName: '',
        orgCode: '',
        orgType: '社会团体',
        orgRegDate: undefined,
        realName: '',
        nationality: '中国大陆籍',
        idCardNo: '',
      },
      user: {},
      orgFileList1: [],
      orgFileList2: [],
      idFileList1: [],
      idFileList2: [],
      idFileList3: [],
      registing: false,
      applying: false,
      registed: false,
      second: 60,
      verified: false,
      timer: null,
      previewVisible: false,
      previewImage: null,
    }
  },
  computed: {
    orgTypes: vm => vm.$store.state.app.orgTypes,
    nationalities: vm => vm.$store.state.app.nationalities,
  },
  watch: {
    second(val) {
      if (val <= 0) {
        this.verified = false
        this.clearTimer()
      }
    },
  },
  methods: {
    ...mapActions(['verifySms', 'regist']),
    showBox() {
      this.$message.destroy()
      let { phone } = this.registForm
      if (!this.$utils.isValidPhone(phone)) {
        this.$message.warning('请输入正确的手机号码')
        this.$refs.phone.focus()
        return
      }
      this.$refs.verify.show()
    },
    verifySuccess(params) {
      this.$message.destroy()
      let { phone } = this.registForm
      registSms({ phone, captchaVerification: params.captchaVerification })
        .then(({ data }) => {
          if (data.success) {
            this.$message.success('验证码已发送至手机')
            this.verified = true
            this.second = 60
            this.startCountdown()
          } else {
            this.$message.warning(data.msg)
          }
        })
        .catch(err => {
          if (err) {
            this.$message.warning(err)
          }
        })
    },
    startCountdown() {
      this.clearTimer()
      let vm = this
      const countdown = () => {
        vm.second--
      }
      vm.timer = setInterval(() => {
        countdown()
      }, 1000)
    },
    clearTimer() {
      if (this.timer) {
        clearInterval(this.timer)
      }
    },
    onChange(e) {
      this.checked = e.target.checked
    },
    handleUploadOrgFileChange1(info) {
      this.orgFileList1 = this.handleUploadFile(info)
    },
    handleUploadOrgFileChange2(info) {
      this.orgFileList2 = this.handleUploadFile(info)
    },
    handleIdFileChange1(info) {
      this.idFileList1 = this.handleUploadFile(info)
    },
    handleIdFileChange2(info) {
      this.idFileList2 = this.handleUploadFile(info)
    },
    handleIdFileChange3(info) {
      this.idFileList3 = this.handleUploadFile(info)
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.$utils.getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    handleRegist() {
      let { phone, password, rePassword, userName, smscode } = this.registForm
      this.$message.destroy()
      if (userName.trim() === '') {
        this.$refs.userName.focus()
        this.$message.warning('请输入用户名')
        return
      }
      if (!this.$utils.isValidPwd(password)) {
        this.$refs.password.focus()
        this.$message.warning('请输入包含英文字母和数字且不低于6位的密码')
        return
      }
      if (rePassword === '') {
        this.$refs.rePassword.focus()
        this.$message.warning('请输入确认密码')
        return
      }
      if (rePassword !== password) {
        this.$refs.rePassword.focus()
        this.$message.warning('两次输入的密码不一致')
        return
      }
      if (!this.$utils.isValidPhone(phone)) {
        this.$message.warning('请输入正确的手机号码')
        this.$refs.phone.focus()
        return
      }
      if (smscode === '') {
        this.$refs.smscode.focus()
        this.$message.warning('请输入短信验证码')
        return
      }
      this.registing = true
      userRegist(userName, password, phone, smscode)
        .then(({ data }) => {
          let { success, code, msg, result } = data
          if (success && result) {
            this.$message.success('注册成功，请继续完善申请材料')
            this.currentStep = 1
            this.userId = result.id
          } else {
            this.$message.warning(msg)
            if (code === 1001) {
              this.$refs.userName.focus()
            } else if (code === 1002) {
              this.$refs.phone.focus()
            }
          }
          this.registing = false
        })
        .catch(err => {
          if (err) {
            this.$message.warning(err)
          }
          this.registing = false
        })
    },
    handleApply() {
      let { userType, orgName, orgType, orgCode, orgRegDate, realName, nationality, idCardNo } = this.applyForm
      let code = null
      let fileIds = []
      let { userId, orgFileList1, orgFileList2, idFileList1, idFileList2, idFileList3 } = this
      this.$message.destroy()
      if (userType === 1) {
        // 组织机构
        if (orgName.trim() === '') {
          this.$refs.orgName.focus()
          this.$message.warning('请输入机构名')
          return
        }
        if (!orgType) {
          this.$refs.orgType.focus()
          this.$message.warning('请选择机构性质')
          return
        }
        if (orgCode.trim() === '') {
          this.$refs.orgCode.focus()
          this.$message.warning('请输入机构代码')
          return
        }
        if (!orgRegDate) {
          this.$refs.orgRegDate.focus()
          this.$message.warning('请选择注册日期')
          return
        }
        if (orgFileList1.length === 0 && orgFileList2.length === 0) {
          this.$message.warning('请上传证件')
          return
        }
        fileIds = orgFileList1.concat(orgFileList2).map(file => file.fileId)
        realName = orgName.trim()
        code = orgCode.trim()
      } else if (userType === 2) {
        // 个人
        if (realName.trim() === '') {
          this.$refs.realName.focus()
          this.$message.warning('请输入姓名')
          return
        }
        if (!nationality) {
          this.$refs.nationality.focus()
          this.$message.warning('请选择国籍')
          return
        }
        if (idCardNo.trim() === '') {
          this.$refs.idCardNo.focus()
          this.$message.warning('请输入身份证号码')
          return
        } else if (!this.$utils.isValidIDCard(idCardNo.trim())) {
          this.$refs.idCardNo.focus()
          this.$message.warning('请输入正确的身份证号码')
          return
        }
        if (idFileList1.length === 0) {
          this.$message.warning('请上传身份证正面')
          return
        }
        if (idFileList2.length === 0) {
          this.$message.warning('请上传身份证反面')
          return
        }
        if (idFileList3.length === 0) {
          this.$message.warning('请上传手持证件照')
          return
        }
        code = idCardNo.trim()
        realName = realName.trim()
        fileIds = [idFileList1[0].fileId, idFileList2[0].fileId, idFileList3[0].fileId]
      }
      let params = {
        id: userId,
        userType,
        orgRegDate,
        realName,
        code,
        orgType,
        nationality,
        fileIds,
      }
      this.applying = true
      userApply(params)
        .then(({ data }) => {
          if (data.success) {
            this.registed = true
          } else {
            this.$message.warning(data.msg)
          }
          this.applying = false
        })
        .catch(err => {
          if (err) {
            this.$message.warning(err)
          }
          this.applying = false
        })
    },
    loadUser() {
      fetchById(this.userId).then(({ data }) => {
        if (data.success) {
          let user = data.result
          let { userType, state, realName, orgType, orgRegDate, code, nationality, files, phone } = user
          if (state === 0 || state === -1) {
            let form = {
              ...this.applyForm,
              userType,
              orgType,
              realName,
              nationality,
              orgRegDate,
            }
            if (userType === 1) {
              form.orgCode = code
              form.orgName = realName
            } else if (userType === 2) {
              form.idCardNo = code
            }
            this.applyForm = form
            let orgFileList1 = []
            let orgFileList2 = []
            let idFileList1 = []
            let idFileList2 = []
            let idFileList3 = []
            files.forEach(file => {
              let { fileType, id, fileOriginName } = file
              let uploadedFile = {
                uid: id,
                fileId: id,
                name: fileOriginName,
                status: 'done',
                ...file,
                url: `${this.baseURL}/file/img?id=${id}&belongId=${user.id}&fileSign=U01`,
              }
              if (fileType === '101') {
                orgFileList1.push(uploadedFile)
              } else if (fileType === '102') {
                orgFileList2.push(uploadedFile)
              } else if (fileType === '201') {
                idFileList1.push(uploadedFile)
              } else if (fileType === '202') {
                idFileList2.push(uploadedFile)
              } else if (fileType === '203') {
                idFileList3.push(uploadedFile)
              }
            })
            this.orgFileList1 = orgFileList1
            this.orgFileList2 = orgFileList2
            this.idFileList1 = idFileList1
            this.idFileList2 = idFileList2
            this.idFileList3 = idFileList3
            this.user = user
            this.currentStep = 1
            this.registForm.phone = phone
          } else {
            this.$store.dispatch('logout')
            this.currentStep = 0
          }
        }
      })
    }
  },
  created () {
    let { action } = this.$route.query
    let userinfo = this.$store.state.user.userinfo
    if (action === 'fill' && userinfo.id) {
      this.userId = userinfo.id
      this.currentStep = 1
      this.loadUser()
    }
  },
}
</script>

<style lang="stylus"></style>
